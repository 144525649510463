// engine Actions
export const engineAction = {
  ENGINE_LOAD_INITIATE: "ENGINE_LOAD_INITIATE",
  ENGINE_LOAD_SUCCESS: "ENGINE_LOAD_SUCCESS",
  ENGINE_LOAD_FAILURE: "ENGINE_LOAD_FAILURE",



  UPDATE_ENGINE_STATE: "UPDATE_ENGINE_STATE",

  CLEAR_ADMIN_DATA: "CLEAR_ADMIN_DATA",
};

// product Actions
export const productAction = {
  GET_PRODUCTS_INITIATE: "GET_PRODUCTS_INITIATE",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",

  UPDATE_PRODUCT_STATE: "UPDATE_PRODUCT_STATE",

  CLEAR_PRODUCT_DATA: "CLEAR_PRODUCT_DATA",
};

// configuration Actions
export const configurationAction = {

  GET_ROOM_SIZE_INITIATE: "GET_ROOM_SIZE_INITIATE",
  GET_ROOM_SIZE_SUCCESS: "GET_ROOM_SIZE_SUCCESS",
  GET_ROOM_SIZE_FAILURE: "GET_ROOM_SIZE_FAILURE",

  GET_HINGES_INITIATE: "GET_HINGES_INITIATE",
  GET_HINGES_SUCCESS: "GET_HINGES_SUCCESS",
  GET_HINGES_FAILURE: "GET_HINGES_FAILURE",

  PLACE_ORDER_INITIATE: "PLACE_ORDER_INITIATE",
  PLACE_ORDER_SUCCESS: "PLACE_ORDER_SUCCESS",
  PLACE_ORDER_FAILURE: "PLACE_ORDER_FAILURE",

  UPDATE_CONFIGURATION_STATE: "UPDATE_CONFIGURATION_STATE",

  CLEAR_CONFIGURATION_DATA: "CLEAR_CONFIGURATION_DATA",

};

// cart Actions
export const cartAction = {
  GET_CART_DATA_INITIATE: "GET_CART_DATA_INITIATE",
  GET_CART_DATA_SUCCESS: "GET_CART_DATA_SUCCESS",
  GET_CART_DATA_FAILURE: "GET_CART_DATA_FAILURE",

  ADD_TO_CART_INITIATE: "ADD_TO_CART_INITIATE",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
  ADD_TO_CART_FAILURE: "ADD_TO_CART_FAILURE",

  DELETE_CART_ITEM_INITIATE: "DELETE_CART_ITEM_INITIATE",
  DELETE_CART_ITEM_SUCCESS: "DELETE_CART_ITEM_SUCCESS",
  DELETE_CART_ITEM_FAILURE: "DELETE_CART_ITEM_FAILURE",

  UPDATE_CART_STATE: "UPDATE_CART_STATE",

  CLEAR_CART_DATA: "CLEAR_CART_DATA"
}

